import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				AceTennis – Kortunuzu Bugün Rezerve Edin
			</title>
			<meta name={"description"} content={"AceTennis'te en kaliteli tenis kortu kiralama hizmetlerini keşfedin. Kortlarımız maçlar, antrenmanlar ve dostluk oyunları için mükemmeldir. Şimdi rezervasyon yaparak üstün bir tenis deneyimi yaşayın!"} />
			<link rel={"shortcut icon"} href={"https://glowesbair.org/img/5738682.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowesbair.org/img/5738682.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowesbair.org/img/5738682.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://glowesbair.org/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 900 82px/1.2 --fontFamily-mono"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					AceTennis
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--headline3"
					lg-text-align="center"
					text-align="center"
				>
					Kortunuzu bugün rezerve edin
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Şimdi Rezervasyon Yapın
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				sm-padding="60px 36px 60px 36px"
				background="#e2e1e1"
			>
				<Box
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						AceTennis'e Hoş Geldiniz
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="--greyD3" md-text-align="center">
						AceTennis, yüksek kaliteli tenis kortları kiralamak için ana destinasyonunuzdur. Tesisimiz, acemilerden profesyonellere kadar her seviyeden oyuncuya yöneliktir. Servis pratiği yapmak veya rekabetçi bir maç düzenlemek için bir yer arıyorsanız, kortlarımız olağanüstü bir tenis deneyimi için mükemmel bir mekandır.
						<br />
						<br />
						Tenis kortlarımız, en iyi oyun koşullarını sağlamak için titizlikle bakım görmektedir. Bakımlı yüzeyleri ve yeterli alanıyla mükemmel bir oyun için ihtiyacınız olan her şeyi bulacaksınız. Ziyaretinizi daha keyifli hale getirmek için konforlu oturma alanları, soyunma odaları ve temiz duşlar gibi modern olanaklar sunuyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://glowesbair.org/img/2.jpg) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Uygun fiyatlar ve esnek rezervasyon
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 22px/1.5 --fontFamily-sans" color="#505257">
					AceTennis, üstün bir tenis deneyimi sunmayı amaçlamaktadır. İşte müşterilerimizin bizi tercih etme nedenleri:
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Yüksek kaliteli kortlar: Kortlarımız en yüksek standartlara uygun olarak bakım görmektedir ve mükemmel oyun koşulları sağlar.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Müşteri odaklı hizmet: Memnuniyetinizi ön planda tutuyoruz ve beklentilerinizi aşmaya çalışıyoruz.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Elverişli konum: Geniş otopark ve iyi ulaşım bağlantıları sayesinde tesisimize kolayca ulaşabilirsiniz. Hizmet çeşitliliği: Derslerden etkinlik organizasyonuna kadar geniş bir hizmet yelpazesi sunuyoruz.
					</Text>
				</Box>
				<Text margin="0px 0px 25px 0px" font="normal 300 22px/1.5 --fontFamily-sans" color="#505257">
					Misafirperver bir ortam yarattığımız için gurur duyuyoruz ve tenis keyfini doyasıya yaşamanız için buradayız.
				</Text>
				<Box text-align="center">
					<Link
						href="/contacts"
						padding="12px 24px 12px 24px"
						color="--light"
						background="--color-primary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 0px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s ease-in-out 0s"
						max-width="200px"
						text-align="center"
					>
						Rezervasyon Yap
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});